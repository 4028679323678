@import 'styles/mixins';
@import 'styles/breakpoints';

.productCategoryPage {
    min-height: 100%;
    padding: 0;
    margin-top: var(--root-margin-top, 0);
}

.callout {
    padding: 0 var(--block-padding-side);
}

.productGridFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 var(--block-padding-side);
}

.productGrid {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;

    &.isRounded {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }
}

@include large {
    .productGrid {
        flex-flow: row wrap;

        .gridCard {
            max-width: 50%;
            flex: 1 0 50%;

            &.landscape {
                max-width: 100%;
                flex: 2;
            }

            &.fullWidth {
                max-width: 100%;
                flex: 1 0 100%;
            }
        }
    }
}
