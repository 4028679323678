@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

.root {
    min-height: 100%;
    margin-top: calc(var(--header-height) * -1);
}

.hero {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;

    // used to set scroll parallax top of viewport
    padding-top: var(--header-height);
    background-color: #ffefc6;
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: var(--root-margin-top, 0);
}

.title {
    width: 85vw;
    order: 1;
    color: var(--colors-blue);
    font-family: $GTWalsheimUltraBold;
    font-size: 15vw;
    font-style: normal;
    font-weight: 800;
    line-height: 85%; /* 4.08rem */
    text-align: center;
    text-transform: uppercase;
    text-wrap: initial;
}

.headingGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--block-padding-end) 0 0;
}

.heroImage {
    position: relative;
    z-index: 1;
    left: -4%;
    width: 145%;
    order: 3;
    margin-top: 0;
}

.cta {
    order: 2;
    margin-top: 2.4rem;
}

.nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 2.4rem;

    img {
        display: block;
        width: 100%;
    }
}

.link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 10%);
    gap: 1.4rem;
    text-decoration: none;

    &:last-child {
        border-bottom: none;
    }
}

.space {
    display: none;
}

.linkText {
    flex: 1;
    color: var(--colors-blue);
}

.aspectBox {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 7.2rem;
    height: 7.2rem;
    align-items: center;
}

.category {
    margin-bottom: 10px;
}

.productGrid {
    padding: 0;
    margin: 0;
    list-style: none;
}

@include medium {
    .root {
        --content-area: min(
            calc(100vw - var(--block-padding-side) - var(--block-padding-side)),
            1440px
        );
        --title-top-margin: 6.6vw;
        --product-scale: 0.75;
    }

    .hero {
        // &::before {
        //     position: absolute;
        //     top: calc(var(--content-area) * 0.46);
        //     display: block;
        //     width: 100%;
        //     height: calc(var(--content-area) * 0.248);
        // //    background: #1641b7;
        //     content: '';
        // }
    }

    .headingGroup {
        align-items: flex-start;
        align-self: flex-start;
        margin-top: calc(var(--content-area) * 0.08);
        margin-left: var(--block-padding-side);
    }

    .cta {
        order: 3;
        margin-top: min(calc(var(--content-area) * 0.035), 4.8rem);
        transition: transform 0.1s ease-out;
    }

    .heroImage {
        --image-width: calc(var(--content-area) * var(--product-scale));

        position: absolute;
        top: calc(var(--content-area) * 0.122);
        right: calc(
            var(--block-padding-side) - calc(var(--image-width) * 0.145)
        );
        left: auto;
        width: var(--image-width);
        order: 2;
        transform: translateY(-12%);
    }

    .image {
        margin-top: 0;
    }

    .title {
        max-width: calc(var(--content-area) * 0.8);
        font-size: calc(var(--content-area) * 0.089);
        text-align: left;
        text-wrap: pretty;
        transition: transform 0.1s ease-out;
    }

    .linebreak {
        display: none;
    }

    .space {
        display: inline;
    }

    .nav {
        width: 100%;
        max-width: 81rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2.4rem var(--block-padding-side);
        margin-top: calc(var(--content-area) * 0.2);
    }

    .link {
        display: flex;
        width: auto;
        min-width: min-content;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem;
        border-bottom: none;
        gap: 1.4rem;
        text-decoration: none;

        &:last-child {
            border-bottom: none;
        }

        @media (hover: hover) {
            &:hover .linkText {
                color: var(--colors-blueOcean);
            }

            &:hover .aspectBox {
                transform: translateY(-10%);
            }
        }
    }

    .aspectBox {
        width: 8rem;
        height: 8rem;
        align-items: flex-end;
        transform: translateY(0%);
        transition: transform $default $easeInOutQuad;
    }

    .linkText {
        text-align: center;
        text-transform: uppercase;
    }

    .linkIcon {
        display: none;
    }
}

@include large {
    .nav {
        width: 100%;
        max-width: var(--block-max-width-plus-padding);
        flex-wrap: nowrap;
    }

    .aspectBox {
        width: 9rem;
        height: 9rem;
    }

    .link {
        width: 100%;
    }
}

@include xLarge {
    .nav {
        flex-wrap: nowrap;
    }

    .aspectBox {
        width: 12rem;
        height: 12rem;
    }
}
